// extracted by mini-css-extract-plugin
export var faqDiv = "faq-module--faqDiv--1llsH";
export var faqheading = "faq-module--faqheading--t2STB";
export var faqdesc = "faq-module--faqdesc--3q9On";
export var faqssinit = "faq-module--faqssinit--MAFSZ";
export var faqnsHeader = "faq-module--faqnsHeader--FMP7z";
export var propmeSubDiv2 = "faq-module--propmeSubDiv2--1jGnq";
export var inputname = "faq-module--inputname--29O0v";
export var plusimg = "faq-module--plusimg--3rTWs";
export var plusimgDiv = "faq-module--plusimgDiv--2YeW2";
export var faqanswerDiv = "faq-module--faqanswerDiv--1G_Ba";
export var highlightlinks = "faq-module--highlightlinks--1w3nI";
export var faqrow = "faq-module--faqrow--20Yiu";
export var faqcolumn = "faq-module--faqcolumn--3NnVV";