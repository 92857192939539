import React from 'react';
import Layout from '../components/layout/layout'
import * as styles from '../components/faq.module.css'
import search from '../images/search.svg'
import Plus from '../images/plus.svg';
import minus from '../images/minus.svg';

class Faq extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showAnswer: [false, false, false, false, false, false, false, false],
    }
  }

  hideAnswer = (num) => {
    this.setState((prevState) => {
      const newItems = [...prevState.showAnswer];
      newItems[num] = !newItems[num];
      return {showAnswer: newItems};
    })
  }

  render(){
    return(
      <Layout>
        <div className={styles.faqDiv}>
          <h1 className={styles.faqheading}>Frequently Asked Questions</h1>
          <p className={styles.faqdesc}>Answers to the most frequent questions concerning PropMe, Online Betting and Betting in West Virginia.</p>
          <div className={styles.Qnsdiv}>
          <div className="row" id={styles.faqrow}>
              <div className="col-lg-3" id={styles.faqcolumn}>
                <div className={styles.faqssinit}>
                  <text className={styles.faqnsHeader}>About PropMe</text>
                </div>
              </div>
              <div className="col-lg-9">
                <div className={styles.propmeSubDiv2}>
                  <div className={styles.inputname} onClick={() => this.hideAnswer(0)}>
                    <text>Who is PropMe?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[0] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[0] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>PropMe brings new technology and new games to the online gaming market. Our first product is called PropMe. PropMe is an innovative gaming platform. We're different in that we are focused on building games and features using custom proposition bets. This makes it easier to play and allows us to create games on any sport or event. We are focused on creating new user experiences. The revolution is here...come join us!!!!</p>
                      <p>Go to the JOIN page and and register. We have giveaways coming soon!</p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(1)}>
                    <text>What is PropMe?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[1] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[1] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>PropMe truly stands out as a first-of-its-kind Prop marketplace. PropMe makes betting easy for new and casual, as well as seasoned, players, through its use of straightforward proposition bets. PropMe is poised to unlock this largely untapped target audience in the US. To date studies on the target audience for sports betting have centered on the DFS market, and/or very early adopters, primarily because of federal restrictions on gambling. PropMe's efforts are instead focused on providing exciting, easily-accessible gambling experiences to new players (e.g., eSports) and the casual gambling fans.</p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(2)}>
                    <text>What is an eSports Contest?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[2] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[2] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>eSports - Contest is a new feature we launched this year designed to allow people watching eSports, or any event, to bet on custom props in a pick'em contest format.</p>
                      <p> The eSports host can easily create a party and invite their friends via email or text. The invitees simply load the app and then click on the invite link. The link will take the players directly to their party page to make their picks - from a list of custom props.</p>
                      <p> The props are based on the eSport. Once the player selects the props, we score each prop and rank your users in real time based on the game situations. You simply watch the rankings via your party leaderboard!</p>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
            <div className="row" id={styles.faqrow}>
              <div className="col-lg-3" id={styles.faqcolumn}>
                <div className={styles.faqssinit}>
                  <text className={styles.faqnsHeader}>Partnering With Us</text>
                </div>
              </div>
              <div className="col-lg-9">
                <div className={styles.propmeSubDiv2}>
                  <div className={styles.inputname} onClick={() => this.hideAnswer(3)}>
                    <text>What's the catch?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[3] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[3] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>There is no catch. We make money when you make money. Simple. You will have full transparency on fees, payouts, etc.</p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(4)}>
                    <text>Why are you doing this?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[4] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[4] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>There are five casinos in WV, four of the five are owned by out-of-state companies.  There are over 2000 bars and restaurants in WV, including our team.  It's time to keep the money in WV.   PropMe has a strategic connection to leadership in the WV Restaurant and Bar Association and limited video lottery association. </p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(5)}>
                    <text>How much can you make through our PPC?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[5] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[5] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>We have a 10% and 20% revenue share model.  A bar can make between $16,000 and $250,000 per year, depending on the number of users and contests we run.</p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(6)}>
                    <text>Do I need any investment, equipment or staff?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[6] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[6] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>No. Once you sign up with PropMe, we are your mobile gaming application. Any reports or technical support will be provided.</p>
                    </div>
                    :
                    null
                  }
                  <div className={styles.inputname} onClick={() => this.hideAnswer(7)}>
                    <text>What do I have to do to sign up with PropMe?</text>
                    <div className={styles.plusimgDiv}>
                      {
                        this.state.showAnswer[7] == false ?
                        <img className={styles.plusimg} src={Plus} alt="landingimg" />
                        :
                        <img className={styles.plusimg} src={minus} alt="landingimg" />
                      }
                    </div>
                  </div>
                  {
                    this.state.showAnswer[7] == true ?
                    <div className={styles.faqanswerDiv}>
                      <p>Simply sign with PropMe - we have different revenue sharing models and want to be your mobile gaming partner. Contact us at: <text className={styles.highlightlinks}>admin@propmellc.com</text></p>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </Layout>
    )
  }
}

export default Faq